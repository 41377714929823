<template>
    <section class="w-100">
        <slot name="header"></slot>
        <div v-if="isLoaded">
            <slot></slot>
        </div>
        <p v-if="!isLoaded" class="text-center">
            <Spinner :size="size" />
        </p>
    </section>
</template>

<script>
export default {
    props: {
        value: { required: true },
        size: { default: '5rem' },
    },
    computed: {
        isLoaded() {
            return this.value !== undefined && this.value !== null;
        },
    },
};
</script>
